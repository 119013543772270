import { createTheme } from "@mui/material/styles";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });


const FormTheme = createTheme({
    palette: {
        primary: createColor('#3399FF'),
    },
    components: {
        // Inputs
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: "#080A52",
                    borderRadius: 8,
                    "& input::placeholder": {
                        color: `#050529`,
                        opacity: 0.5,
                    },
                    "& textarea::placeholder": {
                        color: `#050529`,
                        opacity: 0.5,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid #ccc`,
                    },
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: `1px solid #ccc`
                        },
                    },
                    "&:hover:not(.Mui-focused)": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ccc",
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#808080', // grey background
                        color: '#000000', // black text
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: '#808080', // grey background
                        color: '#000000', // black text
                        opacity: '1 !important',
                    },
                },
            },
        },

    },
    typography: {
        fontFamily: [
            'Univia Pro',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
});

export default FormTheme;