import Box from '@mui/material/Box';
import { useTheme } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import { useAuth } from 'contexts/AuthProvider';
import usePageTitle from 'hooks/usePageTitle';
import Notification from 'messages/Notification';
import LoginForm from 'pages/Login/LoginForm';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

const Login = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [result, setResult] = useState({
    "type": "",
    "message": "",
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const confirm = searchParams.get("confirm");
  usePageTitle('Sign In');

  if (user) {
    navigate('/dashboard');
  }

  const verifyEmail = useCallback(async () => {
    if (searchParams.get("confirm") && searchParams.get("id")) {
      const response = await axios.request({
        method: "POST",
        url: process.env.REACT_APP_API_URL + "/verify-email",
        data: {
          confirm: searchParams.get("confirm"),
          id: searchParams.get("id")
        }
      });

      if (response.status === 200) {
        //remove the search params
        setVerified(true);
      }
    }
  }, [confirm]);

  useEffect(() => {
    verifyEmail().catch(err => console.log(err))
  }, []);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      minHeight="0"
      textAlign="center"
      sx={{ my: 15, maxWidth: '550px', mx: 'auto', px: isMobile ? "30px" : "0" }}
    >
      {(verified === true && result.type === '') && Notification("success", "Your account has been verfied.")}
      {result.type === 'error' && Notification("error", result.message ?? "Invalid credentials.")}
      <Typography variant="h6" sx={{ mb: 0, fontWeight: 'bold', color: "#080A52" }}>Sign into your account</Typography>
      <LoginForm updateResult={setResult} />

      {/* <Box
        component="img"
        src={LmpLogo} // Replace with the real image URL
        alt="LMP Logo"
        sx={{ width: '110px', mt: 10, filter: 'invert(1)' }}
      /> */}
    </Box>
  )
};

export default Login;