import usePageTitle from "hooks/usePageTitle";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscriptionSuccess = () => {
    const navigate = useNavigate();

    usePageTitle("Subscription Success");

    return (
        <Box sx={{ marginBottom: '300px', marginTop: '80px', width: "100%", marginX: "auto", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontFamily: "Univia Pro", fontSize: "25px", textAlign: 'center' }}>
                Congratulations on your successful subscription
            </Typography>
            <Button variant="contained" sx={{
                width: 'fit-content',
                fontWeight: '700', borderRadius: 28,
                margin: '20px auto',
                px: 3, color: "#fff", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            }} onClick={() => navigate("/dashboard")}>
                Go To Dashboard
            </Button>
        </Box >
    );
};

export default SubscriptionSuccess;
