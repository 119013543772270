import { Box, Container, Typography, createTheme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import usePageTitle from "hooks/usePageTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function TermsAndConditionsPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const customTheme = createTheme({
        typography: {
            fontFamily: "Univia Pro medium",
            body1: {
                fontFamily: "Neue Haas Grotesk Display light",
            },
        },
    });

    usePageTitle("Terms and Conditions");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Container maxWidth="md">
            <ThemeProvider theme={customTheme}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        py: 4,
                        maxWidth: "1210px",
                        margin: "50px auto",
                        px: isMobile ? "30px" : "0"
                    }}
                >
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                        <Typography variant={isMobile ? "h4" : "h3"}>Terms and Conditions</Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingX: '30px' }}>
                        <Typography variant="h5">Introduction</Typography>
                        <Typography variant="body1">
                            Welcome to CrashLaw24 (“the service”), a service provided by LMP Legal Limited (“we”, “us”, or “our”). By subscribing to and using CrashLaw24, you (“user”, “client” or “driver”) agree to these Terms and Conditions. If you
                            do not agree, you must not use the service. We are committed to providing accessible, reliable, and high-quality legal support to all CrashLaw24 users, especially during critical during critical situations. The provision
                            of the service will be on these conditions to the exclusion of all other terms and conditions, including any terms and conditions which the client purports to apply.
                        </Typography>

                        <Typography variant="h5">Overview of the service.</Typography>
                        <Typography variant="body1">
                            CrashLaw24 provides personal drivers with immediate access to legal advice and representation in the event of a serious roadside collision. We aim to ensure that users receive timely and effective legal support during
                            highly stressful situations, acknowledging the critical importance of early intervention. The service includes: Access to a dedicated Crisis Line, available 24 hours a day, 7 days a week, including public holidays.
                            Provision of immediate legal advice via telephone from the moment of notification following the incident. Police station accredited legal representatives available to attend the police station as required during any
                            initial police interview/questioning. The representative will ensure, subject to reasonable notice, that they will be at the police station where the driver is being detained at the relevant time in order to provide
                            representation ahead of and during interview. Post-interview follow-up advice. We shall proceed on the basis of factual information we receive from you, and rely upon you to tell us as soon as possible if anything occurs
                            which renders any information previously given to us as incorrect, inaccurate, or incomplete. This information will form the basis of our advice to you. We shall not be responsible for any failure to advise or comment on
                            any matter which falls outside the scope of the instructions given to us by you. Our advice is limited to the specific circumstances you describe. Broader legal issues outside these circumstances require separate
                            engagement. In the event that you have any claim against any other party we shall have no responsibility for advising you about it, nor to act on your behalf to recover any sums that may be owing to you, unless we
                            expressly accept instructions from you to do so and we send you a separate letter of engagement and new terms and conditions in relation to those instructions. Advice given by us is provided in light of the instructions to
                            which it relates, and for the benefit of you only. It may not be used or relied upon for any other purpose, or by any person other than you.
                        </Typography>

                        <Typography variant="h5">Eligibility</Typography>
                        <Typography variant="body1">
                            CrashLaw24 is available to individuals over the age of 18 with a valid driver’s licence residing in England or Wales. The service is available for both personal and work-related driving, but excludes corporate coverage.
                            Users must register via the CrashLaw24 website, providing accurate information including name, email, phone number and payment details. Failure to provide accurate information may result in suspension or termination of
                            access to the service. All users must verify their identity before subscribing to the service, in order for LMP Legal to comply with their regulatory obligations.
                        </Typography>

                        <Typography variant="h5">Subscription and payment.</Typography>
                        <Typography variant="body1">
                            The monthly subscription fee is £4.99, payable in advance. Payments are handled by Stripe. Users must refer to Stripe’s terms for details regarding their payment data processing. LMP Legal does not hold any payment data
                            for CrashLaw24 users. If a payment fails, access to the service may be suspended until the issue is resolved. Subscribers can cancel at any time in their CrashLaw24 dashboard. Access to the service continues until the end
                            of the billing cycle. No pro-rata refunds are available. If you experience any issues with billing or service access, our team will work promptly to resolve the matter to ensure minimal disruption to your coverage.
                        </Typography>

                        <Typography variant="h5">Using the service.</Typography>

                        <Typography variant="body1">
                            The Crisis Line is available through the CrashLaw24 app for legal advice from the moment of notification at the scene of a road traffic incident. The driver must contact the dedicated CrashLaw24 Crisis Line immediately
                            following the incident (after ensuring the safety of themselves and others, and ensuring the emergency services have been contacted) to receive support. Users will be prompted to confirm that the incident is of a serious
                            nature, such that immediate legal advice is appropriate, before being directed to dial the Crisis Line. A serious incident is defined as including at least one of the following: Serious or significant injuries requiring
                            medical attention Serious or major property damage Emergency service attendance Misuse of the service, including calling for non-serious incidents, or sharing access to an account with a third party (e.g. family or
                            friends) could lead to suspension or termination of a user account without refund. Users are responsible for maintaining access to the app and ensuring permissions (e.g. location services, access to device dialler) are
                            enabled. We have taken steps to mitigate the risk of app failure in the case of an incident: Users are prompted to add contact information directly to their device, enabling users to call the Crisis Line without needing to
                            access the app. The Crisis Line will only accept calls made from phone numbers matching a subscriber user’s account. It is the user’s responsibility to ensure their phone number is kept up to date in the app, to ensure
                            their device will be able to call the Crisis Line. After registration, users will receive confirmation of their agreement and guidance on accessing services via the app. We will communicate with you and others by letter,
                            telephone, email, or SMS. Users must let us know if any of these methods are insecure or inappropriate. We may communicate with each other by electronic mail, sometimes attaching further electronic data. Each party accepts
                            the inherent risks (including the security risks of interception of or unauthorised access to such communications, and the risk of viruses or other harmful devices). Notwithstanding that each party has reasonable virus
                            checking procedures, such party will be responsible for virus checking all electronic communications sent to it. It will also be responsible for checking that messages received are complete. In the event of a dispute
                            neither party will challenge the legal evidential standing of an electronic document and the LMP Legal system will be deemed the definitive record of electronic communications and documentation.
                        </Typography>

                        <Typography variant="h5">User obligations.</Typography>

                        <Typography variant="body1">
                            Users must provide accurate and complete information during registration, and update their details promptly if they change. Users must comply with all laws, including the obligation to provide necessary details to the
                            police at the scene of a road traffic incident. Users must not misuse the service or Crisis Line, including providing false information, making frivolous claims or sharing the service with any third party including family
                            or friends. LMP Legal is committed to maintaining a respectful and effective relationship with all clients. We strive to treat users with the utmost respect and professionalism in all interactions, ensuring clear and fair
                            communication. However, in instances where client conduct becomes unreasonable, we may take specific actions up to and including Termination of a user’s access to the service. Unreasonable behaviour includes, but is not
                            limited to: requests significantly beyond reasonable expectations or disproportionate to the scope of the agreed services; requests for us to engage in actions that conflict with our professional obligations, or the
                            standards of our profession as set by the Solicitors Regulation Authority (SRA); expecting us to work to unreasonable timescales or outside of working hours; excessively contacting us to the extent that a disproportionate
                            amount of time is spent on your matter to the detriment of other clients, including: • repeated contact in person, by phone or email etc, to one or more staff members about the same issue; • continually submitting to us
                            duplicated documentation, or documentation with limited relevance to your matter; • persistent refusal to accept a reasonable decision or explanation, or repeatedly requesting a review of the same. use of aggressive,
                            threatening, or abusive language and behaviour towards our staff in any format verbal, written or otherwise, such as: • offensive language and/or shouting; • threats, inflammatory statements or unsubstantiated allegations;
                            • harassment or discriminatory language; • personal or derogatory comments; • any other incident that may cause any member of our staff to feel abused, threatened or intimidated. Clients demonstrating unreasonable conduct
                            will be informed of the concerns regarding their conduct and provided with any decision on further action we may take. The options we will consider are: • limiting telephone contact or requiring communication only in
                            writing; • restricting interactions and correspondence to specific times and/or to certain topics only; • termination of our contract; If we have decided to restrict our service to you in any way and you do not agree with
                            this decision, you can appeal following our complaints procedure.
                        </Typography>

                        <Typography variant="h5">Limitations of coverage.</Typography>

                        <Typography variant="body1">
                            The subscription to the service covers immediate advice at the roadside following the incident, and representation at a single initial police interview only. Further representation, such as court appearances, is not
                            included. Separate engagement is available for ongoing case work with LMP Legal at a discounted rate for CrashLaw24 subscribers. The service provides legal support regardless of fault in an incident, but does not
                            determine, or provide any guarantee of, fault outcomes. The service does not cover cases involving pre-existing charges or matters unrelated to road traffic incidents. The service also excludes: Incidents occurring outside
                            of England and Wales. Incidents not reported promptly via the Crisis Line. Incidents involving a driver not insured to perform the activity being undertaken at the time of the incident.
                        </Typography>

                        <Typography variant="h5">Privacy and confidentiality.</Typography>

                        <Typography variant="body1">
                            The service collects registration details, device information, and location data (if permissions are granted in-app). Refer to the LMP Legal Privacy Policy for full details. Identity verification and payment processing are
                            conducted via Stripe. Users should review Stripe’s Privacy Policy for details on data handling. LMP Legal shall keep confidential all information of or relating to the client which is clearly identifiable as confidential
                            by its nature, and shall not disclose any such information to any third party without the client’s prior written consent, except to the extent that information: is or becomes public knowledge other than by reason of LMP
                            Legal’s breach of this condition; or is known from other sources that are not subject to any restriction on disclosure of that information; or is required to be disclosed by any statutory or regulatory requirement or by
                            any Court order; or is disclosed to LMP Legal professional advisers who need to know the same.
                        </Typography>

                        <Typography variant="h5">Liability.</Typography>
                        <Typography variant="body1">
                            While we strive to provide uninterrupted access to the Crisis Line and legal services, we are not liable for delays caused by technical issues such as phone service/coverage, user error, including device issues, or any
                            other external circumstances beyond our control. Payments and identity verification are managed by Stripe. LMP Legal is not responsible for any data processing or errors caused by third-party providers. Advice provided by
                            LMP Legal through the CrashLaw24 service is based on the information shared by users and does not guarantee specific legal outcomes. Users acknowledge that there is no contract between them and any LMP Legal individual
                            members, employees, or consultants. Any advice given by a member, employee or consultant is given by that person on LMP Legal’s behalf and that person does not assume responsibility to you for that advice. Accordingly,
                            users agree that they will not bring any claim against any individual member, employee, or consultant in respect of any losses suffered or incurred directly or indirectly in connection with the service. You may not rely on
                            any drafts of any advice (whether oral or written), reports, letters, or other document we send to you since they may vary significantly from any final report or advice. LMP Legal will not be liable to the client in
                            contract, tort (including negligence), breach of statutory duty, misrepresentation, restitution, or otherwise for any loss to the extent that such loss: arises as a result of LMP Legal having complied with the client’s
                            instructions or of any failure by the client to comply with any of their obligations under these conditions; and/or is attributable to any Input Material (including where such Input Material is incomplete, incorrect,
                            inaccurate, illegible, out of sequence or in the wrong form) or to any other act or omission of the client (including a failure by the client to implement any recommendation made by LMP Legal); and/or does not relate to or
                            arise out of the services provided by LMP Legal; and/or arises from the corruption of any Deliverable which is stored and/or transmitted to the client electronically; and/or arises from any action or inaction on the part
                            of a Third Party. LMP Legal will not be liable to the client in contract, tort (including negligence), breach of statutory duty, misrepresentation, restitution, or otherwise for any loss of profits or any indirect or
                            consequential loss (including pure economic loss, loss of business, depletion of goodwill and like loss) howsoever caused arising out of or in connection with the services. Unless a client has applied for, and LMP Legal
                            has agreed to, special interest being noted, the total liability of LMP Legal in contract, tort (including negligence), breach of statutory duty, misrepresentation, restitution, or otherwise arising out of or in connection
                            with the services will be limited to the total subscription fee received by LMP Legal and if this limit is set aside by Court ruling, the alternative limit would be the agreed special interest or whatever the indemnity
                            limit is in LMP Legal professional indemnity at the material time. Each of LMP Legal’s employees, agents and sub-contractors may rely upon and enforce the exclusions and restrictions of liability in this condition in that
                            person’s own name and for that person’s own benefit, as if the words “its employees, agents and sub-contractors” followed the words “LMP Legal” wherever it appears in these terms. The Client acknowledges that the above
                            provisions of this condition are reasonable and are reflected in the subscription fee which would be higher without those provisions, and the Client will accept such risk and/or insure accordingly. LMP Legal will not be
                            liable to the Client or be deemed to be in breach of contract by reason of any delay in performing, or any failure to perform, any of LMP Legal’s obligations in relation to the services, if the delay or failure was due to
                            any cause beyond LMP Legal’s reasonable control, including explosion, flood, abnormal weather conditions, fire, war or threat of war, civil disturbance, acts of terrorism, strikes, lock-outs or other labour disputes
                            whether of LMP Legal, the client, or any third party.
                        </Typography>

                        <Typography variant="h5">Warranty.</Typography>

                        <Typography variant="body1">
                            LMP Legal warrants that the services will be provided with reasonable care and skill, but gives no warranty that the provision of the services or the client’s compliance with any advice or recommendations given by LMP
                            Legal will fulfil the client’s obligations arising from: any statute; legislation; European, national, or local laws or regulations; and/or any insurance policy warranties, terms or conditions. The client is advised to
                            read their insurance policies carefully to ensure that any risk management action the client takes complies with the terms and conditions of all of its insurance policies.
                        </Typography>

                        <Typography variant="h5">Termination.</Typography>

                        <Typography variant="body1">
                            Clients may terminate their subscription at any time through the CrashLaw24 online dashboard. We may suspend or terminate access for breach of these Terms or misuse of the service. LMP Legal may, by giving notice in
                            writing to the client, terminate the services with immediate effect where the client is in material breach of any other contract between LMP Legal and the client and/or LMP Legal has terminated or has grounds to terminate
                            that contract. On termination of the services, LMP Legal will not be obliged to complete the services unless it otherwise agrees in writing. The termination of any services howsoever arising is without prejudice to the
                            rights, duties, and liabilities of either party accrued prior to termination and the conditions which expressly or impliedly have effect after termination will continue to be enforceable notwithstanding termination.
                        </Typography>

                        <Typography variant="h5">Complaints.</Typography>

                        <Typography variant="body1">
                            LMP Legal recognises the importance of quality service and sets itself high standards, but things can go wrong in any business, and if for any reason LMP Legal has not met the client’s expectations LMP Legal would like to
                            know. Should this happen then: in the first instance the client should write to our dedicated CrashLaw24 support team at support@crashlaw24.co.uk, or speak to the team on 0333 222 4357. LMP Legal will provide a formal
                            written response within 20 Business Days from the receipt of the original complaint. If the complaint cannot be resolved within this timescale, LMP Legal will write with an explanation as to progress and the likely
                            timescale involved. LMP Legal aims to provide a formal response to all complaints within 40 Business Days of receipt of the original complaint. Where this is not possible, LMP Legal will provide the client with an
                            explanation of why the complaint has not been resolved within this timescale and will give the client a date on or by which LMP Legal expects to be able to respond. If you are not satisfied with our handling of your
                            complaint, you may be able to ask the Legal Ombudsman: Address: PO Box 6806, Wolverhampton WV1 9WJ Website: www.legalombudsman.org.uk. Telephone: 0300 555 0333 to consider your complaint. Normally, you will need to bring a
                            complaint to the Legal Ombudsman within six months of receiving a final written response from us about your complaint. Note that the Legal Ombudsman service cannot be used by businesses or most other organisations unless
                            they are below certain size limits. As well as your right to complain about any of our bills under our Complaints procedure, you can also apply for the bill to be assessed by the Court under Part III of the Solicitors Act
                            1974, in which case the Legal Ombudsman may not consider your complaint. We are regulated by the Solicitors Regulation Authority (SRA) and so are subject to the provisions of the SRA Code of Conduct and the SRA Handbook.
                            Copies can be obtained from the SRA website, http://www.sra.org.uk.
                        </Typography>

                        <Typography variant="h5">General.</Typography>

                        <Typography variant="body1">
                            These Conditions are governed by English law and the Client and LMP Legal irrevocably submit to the exclusive jurisdiction of the English Courts in relation to any dispute connected with these Conditions. To the extent
                            that any provision of these conditions is determined by any judicial or other competent authority to be invalid, unlawful, or unenforceable then that provision will be severed from these conditions, but this will not
                            affect the remainder of these conditions which will continue in full force and effect. The parties will in good faith endeavour to amend these conditions to reflect as nearly as possible the spirit and intention behind the
                            invalid, unlawful or unenforceable provision but so that the amended provision complies with English law. Any rights not expressly granted by LMP Legal in these Conditions are reserved, including but not limited to the
                            right to amend these terms, suspend access for misuse, and restrict service availability. We will exercise our rights responsibly, ensuring any amendments to these terms or service adjustments are clearly communicated to
                            users in advance. Nothing in these conditions is intended, or shall operate, to create a partnership between LMP Legal and the client or to authorise either party to act as agent for the other party.
                        </Typography>
                    </Box>
                </Box>
            </ThemeProvider>
        </Container>
    );
}
