import { Box, Container, ThemeProvider, Typography, createTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";

export default function PrivacyPolicyPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const customTheme = createTheme({
        typography: {
            fontFamily: "Univia Pro medium",
            body1: {
                fontFamily: "Neue Haas Grotesk Display light",
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth="md">
            <ThemeProvider theme={customTheme}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        py: 4,
                        maxWidth: "1210px",
                        margin: "50px auto",
                        px: isMobile ? "30px" : "0",
                    }}
                >
                    <Box sx={{ textAlign: "center", mb: 2 }}>
                        <Typography variant="h3">Privacy Policy</Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <Typography variant="body1">
                            LMP Legal Limited ('we' or 'us' or 'our') gather and process personal information in accordance with this privacy notice and in compliance with the relevant data protection Regulation and laws. This notice provides you
                            with the necessary information regarding your rights and our obligations, and explains how, why, and when we process your personal data.
                        </Typography>

                        <Typography variant="body1">
                            This Policy explains how we use any personal information we may receive about you when you use our websites or when you use or are a recipient of our legal services. Our services are provided to you in conjunction with any
                            of our approved partners.
                        </Typography>

                        <Typography variant="body1">
                            LMP Legal Limited registered office is at Park House, 24 Park Square West, Leeds, West Yorkshire LS1 2PW and we are a company registered in England and Wales under company number 14256565.
                        </Typography>

                        <Typography variant="h5">Information that we collect</Typography>
                        <Typography variant="body1">
                            LMP Legal Limited may collect personal information when: • you use our services for legal advice or representation, • your information is provided by a legal recruitment or employment agency, • you contact us, • your
                            chosen partner contacts us in order to seek legal advice or representation, • you are a recipient of our legal services. We will never collect any unnecessary personal data and do not process personal information in any
                            way other than as specified in this notice.
                        </Typography>

                        <Typography variant="body1">
                            We may collect the following types of information: • Contact Information: Name, address, email address, telephone numbers. • Identification Information: National insurance number, passport or driving licence details, proof
                            of delivery signature, identity verification documentation. • Financial Information: Bank account details, payment history. Payment processing specifically for the mobile app CrashLaw24 is handled by Stripe. While we do
                            not store your payment details in this case, Stripe may collect and process this information in accordance with its Privacy Policy. • Case Details: Information relevant to your case or legal matter, which may include
                            sensitive personal data (such as health data, criminal records). • Website Usage Data: Information about your visits to and use of our website, including IP address, browser type, and access times. • App Usage Data:
                            Information about your visits to and use of our CrashLaw24 app, including IP address, device type and operating system, and upon making a crisis call through the app, approximate location.
                        </Typography>

                        <Typography variant="h5">Why do we collect this information?</Typography>
                        <Typography variant="body1">
                            We collect personal/company information for the purpose of communicating with you about your legal case, and providing legal advice and representation as per your/our client’s/your service provider’s requests. To comply
                            with legal obligations, such as anti-money laundering regulations. To manage and administer our business operations, including client billing and accounting. To enhance and improve your experience of our legal services and
                            website user experience. We may collect information as part of our recruitment process to assess suitability for employment, for the purpose of assessing fitness and legal ability to practice law and to comply with our
                            legal obligations.
                        </Typography>

                        <Typography variant="body1">
                            LMP Legal Limited takes personal privacy very seriously and will never disclose, share, or sell personal data without consent; unless required to do so by law. We only retain personal data for as long as is necessary and
                            for the purpose(s) specified in this notice.
                        </Typography>

                        <Typography variant="body1">
                            We rely on the following legal grounds to process your personal data: • Contractual Necessity: For processing related to providing legal services as per our contract with you. • Legal Obligation: For processing necessary
                            to comply with laws and regulations. • Legitimate Interests: For purposes such as managing business operations and improving services, where these interests are not overridden by your rights. • Consent: Where required, we
                            may rely on your consent (e.g., for marketing communications). You may withdraw consent at any time.
                        </Typography>

                        <Typography variant="body1">
                            How do we collect this information? LMP Legal Limited collects personal information: • Directly from you or your service provider (e.g., if we receive a legal consultation request or correspondence by letter, email, live
                            chat, or telephone, or you register for the CrashLaw24 service online). • Directly from job applicants (e.g., if you submit a job application to us directly via email, letter, or through recruitment partners). • Directly
                            from you if you provide a service.
                        </Typography>

                        <Typography variant="body1">
                            Sharing and disclosing your personal information. We do not share or disclose any of your personal information without your consent, other than for the purposes specified in this notice or where there is a legal
                            requirement. We may share your personal data with: • Service providers: For business support (e.g., IT services, payment processing). • Legal authorities: If required to comply with legal obligations. • Our approved legal
                            partners: Such as counsel, consultants, and experts involved in your case, with your consent. • Other parties: Which includes the police, law enforcement agencies, fraud prevention agencies for the purposes of crime
                            detection, prevention, and/or legal proceedings, insurers for investigating and processing claims, and accountants to meet with our statutory requirements.
                        </Typography>

                        <Typography variant="body1">
                            LMP Legal Limited occasionally uses authorised third parties to provide our legal services and business functions; however, all processors acting on our behalf only process your data in accordance with instructions from us
                            and comply fully with this privacy notice, the data protection laws, and any other appropriate confidentiality and security measures.
                        </Typography>

                        <Typography variant="body1">
                            Safeguarding measures. LMP Legal Limited takes your privacy seriously and takes every reasonable measure and precaution to protect and secure your personal data. We work hard to protect you and your information from
                            unauthorised access, alteration, disclosure, or destruction.
                        </Typography>

                        <Typography variant="h5">How long do we keep your data?</Typography>
                        <Typography variant="body1">
                            LMP Legal Limited retains personal information for only as long as is necessary, and we have strict review and retention policies in place to meet these obligations. • Accounts and finance: We are required under UK tax law
                            to keep accounts information for a minimum of 6 years. This is destroyed after 7 years. We delete payment details immediately after the last payment has been made. • Legal regulatory records: We retain personal information
                            with regard to serious professional conduct offences or excessive infringements for 5 years in order to be able to respond to any regulatory requests. Other analysis is retained for 2 years, as this is also used for our
                            records. Other personal information is destroyed after 3 months following termination of collaboration. • Legal cases: We retain information regarding legal cases for 3 years. • Employment: We retain non-successful job
                            applications for 3 months.
                        </Typography>

                        <Typography variant="h5">Legitimate interests</Typography>
                        <Typography variant="body1">
                            LMP Legal Limited may occasionally process your personal information under the legitimate interests’ legal basis. Where this is the case, we have carried out an assessment where necessary to ensure that we have weighed
                            your interests and any risk posed to you against our own interests, ensuring that they are proportionate and appropriate. We use the legitimate interests’ legal basis for the following: • Sending marketing post and/or
                            emails to business contacts where we have identified our legal services being of benefit to a potential business customer or to keep business customers up to date with industry or legislator updates. Such communications
                            will be relevant, non-intrusive, and you will always have the option to opt-out/unsubscribe at any time.
                        </Typography>

                        <Typography variant="h5">Your rights</Typography>
                        <Typography variant="body1">
                            You have the right to access any personal information that LMP Legal Limited processes about you and to request information about: • What personal data we hold about you. • The purposes of the processing. • The categories
                            of personal data concerned. • The recipients to whom the personal data has/will be disclosed. • How long we intend to store your personal data for. • If we did not collect the data directly from you, information about the
                            source.
                        </Typography>

                        <Typography variant="body1">
                            If you believe that we hold any incomplete or inaccurate data about you, you have the right to ask us to correct and/or complete the information, and we will strive to correct it as quickly as possible; unless there is a
                            valid reason for not doing so, at which point you will be notified.
                        </Typography>

                        <Typography variant="body1">
                            You also have the right to request erasure of your personal data or to restrict processing (where applicable) in accordance with the data protection laws, to exercise your data portability rights, and to be informed about
                            any automated decision-making we may use.
                        </Typography>

                        <Typography variant="body1">
                            If we receive a request from you to exercise any of the above rights, we may ask you to verify your identity before acting on the request; this is to ensure that your data is protected and kept secure.
                        </Typography>

                        <Typography variant="h5">Consequences of Not Providing Data</Typography>
                        <Typography variant="body1">
                            If some information is not passed on to us, then we may not be able to: • Provide legal advice or representation. • Process an application for work. • Arrange for payment for services provided.
                        </Typography>

                        <Typography variant="h5">Contacting us</Typography>
                        <Typography variant="body1">
                            LMP Legal Limited only processes your personal information in compliance with this privacy notice and in accordance with the relevant data protection laws. If you wish to exercise your rights as specified in this notice,
                            raise a complaint regarding the processing of your personal data, or are unsatisfied with how we have handled your information, please contact us at: Data Protection Officer LMP Legal Limited Park House 24 Park Square West
                            Leeds West Yorkshire LS1 2PW Email: hello@lmplegal.co.uk, marking your email for the attention of the Data Protection Officer.
                        </Typography>

                        <Typography variant="body1">You also have the right to lodge a complaint with the supervisory authority Information Commissioner's Office: Wycliffe House Water Lane Wilmslow SK9 5AF Telephone: 0203 123 1113</Typography>

                        <Typography variant="body1">Policy last updated: December 2024</Typography>
                    </Box>
                </Box>
            </ThemeProvider>
        </Container>
    );
}
