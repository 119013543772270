import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import phone from "../assets/images/about-phone.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import CrashlawAppIcon from "../assets/images/crashlaw_app_icon.png";

export default function AboutApp({ keyDiff }: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const handleScrollToKeyDiff = () => {
    if (keyDiff?.current) {
      const targetPosition = keyDiff.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: targetPosition - 50, behavior: "smooth" });
    }
  };

  return (
    <Box id="download-app" sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", margin: "0 auto", justifyContent: "center", alignItems: "center", gap: "", marginY: "67px", maxWidth: "1210px", paddingX: isTablet ? "30px" : "0" }}>
      <Box sx={{ width: isTablet ? "100%" : "50%", display: "flex" }}>
        <img src={phone} alt="phone" style={{ width: isTablet ? "330px" : "100%", margin: isTablet ? "0 auto" : "0" }} />
      </Box>
      <Box sx={{ maxWidth: isTablet ? "100%" : "50%", display: "flex", flexDirection: "column", paddingX: isTablet ? "0" : "50px" }}>
        <Typography sx={{ fontWeight: '700', fontFamily: "Univia Pro", color: "#080A52", fontSize: isTablet ? "40px" : "50px", lineHeight: isTablet ? "47px" : "50px", marginBottom: "16px", marginTop: "22px", textAlign: isMobile ? "left" : isTablet ? "center" : "left" }}>Access to a lawyer minutes after a collision for just <span style={{ color: "#3399FF" }}>£4.99 per month</span></Typography>
        <Typography sx={{ fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", marginBottom: "16px", textAlign: isMobile ? "left" : isTablet ? "center" : "left" }}>You’re confused and shaken, but the police want to know what happened. You mention looking away for a second or glancing at the radio. It feels like an informal chat, but they’re taking down everything you say.</Typography>
        <Typography sx={{ fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", marginBottom: "16px", textAlign: isMobile ? "left" : isTablet ? "center" : "left" }}>Your account in the aftermath of a collision at the roadside can make or break a criminal case against you. Before you say anything, take legal advice. Our criminal lawyers are available at the push of a button 24/7 at the roadside and beyond. </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "16px" }}>
          <img
            src={CrashlawAppIcon}
            alt="app-icon"
            style={{
              width: isMobile ? "55px" : "75px",
              height: isMobile ? "55px" : "75px",
              marginRight: "16px",
              borderRadius: isMobile ? "10px" : "15px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
            }}
          />
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Button
              component="a"
              href={process.env.REACT_APP_APP_STORE_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              size="large"
              sx={{
                flex: 1,
                height: "56px",
                fontSize: {
                  xs: "13.5px",
                  sm: "16px",
                },
              }}
              startIcon={<AppleIcon />}
            >
              <Typography sx={{ fontSize: { xs: "11px", sm: "16px", width: "100%" } }}>
                App Store
              </Typography>
            </Button>
            <Button
              component="a"
              href={process.env.REACT_APP_PLAY_STORE_URL}
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              size="large"
              sx={{
                flex: 1,
                height: "56px",
                fontSize: {
                  xs: "13.5px",
                  sm: "16px",
                },
              }}
              startIcon={<AndroidIcon />}
            >
              <Typography sx={{ fontSize: { xs: "11px", sm: "15px", width: "100%" } }}>
                Play Store
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
