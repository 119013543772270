import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useAuth } from 'contexts/AuthProvider';
import { Formik } from "formik";
import React from 'react';
import { Link } from "react-router-dom";
import * as Yup from "yup";

type LoginProps = {
  updateResult: any
};

const LoginForm: React.FC<LoginProps> = ({ updateResult }) => {
  const { login } = useAuth();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required(),
  });

  return (
    <Box>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          login(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              placeholder="Password"
              name="password"
              type="password"
              onChange={handleChange}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              // color="error"
              fullWidth={true}
              size="large"
              sx={{ my: 2, color: "white" }}
            >
              SIGN IN
            </Button>
          </form>
        )}
      </Formik>
      <Link to="/forgot-password">
        <Typography>Forgot password?</Typography>
      </Link>
    </Box>
  );
};

export default LoginForm;