import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import Question from "../assets/icons/question.svg";
import ArrowUp from "../assets/icons/arrow_circle_up.svg";
import ArrowDown from "../assets/icons/arrow_down.svg";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type faqItem = {
    id: string;
    question: string;
    answer: string;
};

export default function FaqBlock() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const faqData: faqItem[] = [
        {
            id: "1",
            question: "What is CrashLaw24?",
            answer: "CrashLaw24 is a brand new app from law firm, LMP Legal. It’s designed to put you in touch with a criminal defence lawyer in the first few minutes after a collision whatever the time of day or night. We’ll be with you at the roadside over the phone and later in person if you’re asked to attend a formal police interview. ",
        },
        {
            id: "2",
            question: "But aren’t I covered with legal expenses? ",
            answer: "Legal expenses as part of your motor insurance covers your legal costs for ongoing criminal court proceedings if you’re prosecuted. The CrashLaw24 app connects you to a lawyer in the immediate aftermath of a collision when you give your first account of what happened while you’re still at the side of the road. These critical moments are when you really need legal representation. Read our FAQ to learn more ",
        },
        {
            id: "3",
            question: "Who can sign up for CrashLaw24?",
            answer: "If you live in England and Wales and you have a driving licence, you’re eligible to sign up for the CrashLaw24 app. We have a network of lawyers that covers the length and breadth of England and Wales so we can be there in person when you need us at your first police interview. ",
        },
        {
            id: "4",
            question: "What’s included in my subscription?",
            answer: "Your subscription to CrashLaw24 includes 24/7 access to a lawyer in the minutes after a collision. We’ll connect you to a lawyer at the roadside who will represent you over the phone in the immediate aftermath, and we’ll be there in person for your first interview at a police station. ",
        },
        { id: "5", question: "How much does it cost?", answer: "A subscription to CrashLaw24 costs just £4.99/month. There’s no registration fee and you can cancel anytime. " },
        { id: "6", question: "Is the app available on iOS and Android? ", answer: "The CrashLaw24 app is available to download on iOS via the Apple App Store and Android via Google Play. " },
    ];

    const [expanded, setExpanded] = useState<string | false>(faqData[0].id);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ background: "linear-gradient(to bottom, #080A52, #050529)", color: "#fff", marginTop: isTablet ? "0" : "15px", marginX: isTablet ? "0" : "15px", borderRadius: isTablet ? "0" : "15px", paddingX: isMobile ? "30px" : "50px" }}>
            <Box sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", justifyContent: "center", maxWidth: "1210px", width: "100%", margin: "0 auto" }}>
                <Box sx={{ width: isTablet ? "100%" : "50%" }}>
                    <img src={Question} alt="" style={{ paddingTop: isTablet ? "40px" : "92px" }} />
                    <Typography sx={{ fontFamily: "Univia Pro", fontSize: "40px", marginBottom: "20px", lineHeight: "42px", marginTop: "18px" }}>Frequently asked questions</Typography>
                    <Typography sx={{ fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", fontSize: "18px", lineHeight: "22.5px", marginRight: isTablet ? "0" : "100px" }}>
                        If you can’t find the answer in our FAQs, contact the team at LMP Legal for help. Our criminal defence lawyers have years of experience in traffic law, representing clients at all levels.
                    </Typography>
                </Box>
                <Box sx={{ width: isTablet ? "100%" : "50%", paddingTop: isTablet ? "30px" : "190px", display: "flex", flexDirection: "column" }}>
                    {faqData.map((item) => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === item.id}
                            onChange={handleChange(item.id)}
                            sx={{
                                mb: "12px",
                                width: "100%",
                                backgroundColor: expanded === item.id ? "fff" : "#3399FF",
                                borderRadius: "15px !important",
                                paddingY: "5px",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={expanded === item.id ? <img src={ArrowUp} alt="Arrow up" /> : <img src={ArrowDown} alt="Arrow down" />}
                                sx={{
                                    "& .MuiAccordionSummary-expandIconWrapper": {
                                        color: "error.main",
                                        transform: "none !important",
                                    },
                                }}
                            >
                                <Typography fontWeight="bold" sx={{ width: "100%", flexShrink: 0, color: expanded === item.id ? "#080A52" : "#fff", fontSize: "20px", lineHeight: "21px" }}>
                                    {item.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography color="#080A52" whiteSpace="pre-line" sx={{ fontFamily: "Neue Haas Grotesk Display medium", fontWeight: "450", fontSize: "18px", lineHeight: "22.5px" }}>
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Button
                        variant="outlined"
                        sx={{ fontWeight: '700', marginX: isMobile ? "0" : isTablet ? "auto" : "0", textTransform: "none", border: "2px solid white", color: "white", borderRadius: "100px", paddingX: "20px", marginTop: "32px", marginBottom: isTablet ? "30px" : "92px", width: "122px" }}
                        component={Link}
                        to={"/faqs"}
                    >
                        Read more
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
