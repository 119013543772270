import { Box, Typography, Button } from "@mui/material";
import Img from "../assets/images/Firefly-lights.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function CtaBlock() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box
            sx={{
                backgroundImage: `url(${Img})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: isTablet ? "100%" : "500px",
                display: "flex",
                alignItems: "center",
                position: "relative",
                borderRadius: isTablet ? "0" : "15px",
                paddingX: isTablet ? "30px" : "15px",
                margin: isTablet ? "0" : "15px",
                paddingY: isTablet ? "72px" : "0",
            }}
        >
            <div style={{ position: "absolute", top: "0", left: "0", backgroundColor: "#080A5266", width: "100%", height: "100%", opacity: "60%", borderRadius: "15px" }}></div>
            <Box sx={{ maxWidth: "1210px", width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", paddingX: isMobile ? "30px" : "30px" }}>
                <Typography sx={{ fontFamily: "Univia Pro", fontSize: isTablet ? "30px" : "50px", lineHeight: isTablet ? "32px" : "52.5px", color: "white", width: isTablet ? "100%" : "580px", position: "relative", textAlign: isMobile ? "left" : isTablet ? "center" : "left" }}>
                    Don’t incriminate yourself - get legal assistance at the scene of the accident
                </Typography>
                <Button variant="contained" sx={{ marginX: isMobile ? "0" : isTablet ? "auto" : "0", borderRadius: "100px", color: "white", marginTop: "20px", textTransform: "none", fontSize: "16px", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", width: "141px" }} component={Link} to={"/register"}>
                    Register Now
                </Button>
            </Box>
        </Box>
    );
}
