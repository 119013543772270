import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import heroImage from "../assets/images/hero-image.jpeg";
import Itv2 from "../assets/images/ITV2_logo.svg";
import ItvX from "../assets/images/ITVX_logo.svg";
import GB from "../assets/images/GBN_logo.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box sx={{ padding: isMobile ? "0" : "10px", position: "relative" }}>
            <Box
                sx={{
                    backgroundImage: `url(${heroImage})`,
                    height: isMobile ? "400px" : "600px",
                    width: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: isMobile ? "0" : "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    position: "relative",
                }}
            >
                <div style={{ backgroundColor: "#080A5266", position: "absolute", top: "0", left: "0", width: "100%", height: isMobile ? "400px" : "600px", opacity: "70%", borderRadius: isMobile ? "0" : "10px" }}></div>
                <Box sx={{ maxWidth: "1210px", width: "100%", margin: "0 auto", position: "relative", display: "flex", flexDirection: "column", paddingX: isMobile ? "0" : "30px" }}>
                    <Box sx={{ maxWidth: "580px", paddingLeft: isMobile ? "30px" : "0", }}>
                        <Typography sx={{ fontFamily: "Univia Pro", color: "white", fontSize: isMobile ? "50px" : "70px", lineHeight: isMobile ? "52px" : "74px", marginBottom: "20px", position: "relative" }}>
                            24/7 legal helpline for drivers
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", fontSize: "22px", marginBottom: "20px", position: "relative" }}>Legal support at the roadside and beyond</Typography>
                        <Button variant="contained" sx={{ fontWeight: '700', borderRadius: 28, px: 3, color: "#fff", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }} component={Link} to={"/register"}>
                            Register now
                        </Button>
                    </Box>
                    {(!isMobile && <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0 auto",
                            justifyContent: "center",
                            alignItems: "center",
                            width: isMobile ? "100%" : "380px",
                            height: "145px",
                            position: "absolute",
                            bottom: "-134px",
                            right: "0",
                            backgroundColor: "white",
                            borderTopLeftRadius: isMobile ? "0" : "15px",
                            borderTopRightRadius: isMobile ? "0" : "15px",
                            gap: "25px",
                        }}
                    >
                        <Typography sx={{ fontWeight: '700', fontSize: "20px", fontFamily: "Univia Pro", color: "#080A52" }}>As seen on</Typography>
                        <Box sx={{ display: "flex", gap: "35px" }}>
                            <img src={Itv2} alt="" className="logo-hero" />
                            <img src={ItvX} alt="" className="logo-hero" />
                            <img src={GB} alt="" className="logo-hero" />
                        </Box>
                    </Box>)}
                </Box>
            </Box>
            {(isMobile && <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                    width: isMobile ? "100%" : "380px",
                    height: "145px",
                    backgroundColor: "white",
                    borderTopLeftRadius: isMobile ? "0" : "15px",
                    borderTopRightRadius: isMobile ? "0" : "15px",
                    gap: "25px",
                }}
            >
                <Typography sx={{ fontWeight: '700', fontSize: "20px", fontFamily: "Univia Pro", color: "#080A52" }}>As seen on</Typography>
                <Box sx={{ display: "flex", gap: "35px" }}>
                    <img src={Itv2} alt="" className="logo-hero" />
                    <img src={ItvX} alt="" className="logo-hero" />
                    <img src={GB} alt="" className="logo-hero" />
                </Box>
            </Box>)}
        </Box>
    );
}
