import { useState, useEffect } from "react";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import LoginIcon from "@mui/icons-material/Login";
// import LogoutIcon from "@mui/icons-material/Logout";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import LMP from "assets/icons/LMP-header-mobile.svg";
import logoMobile from "assets/images/logo-icon.svg";
import logo from "assets/images/logo.svg";
import { useAuth } from "contexts/AuthProvider";
import { Link, useNavigate, useLocation } from "react-router-dom";

// const navigations = [
//     { title: "Home", url: "/", icon: <HomeIcon /> },
//     { title: "How does it work", url: "/how-does-it-work", icon: <HelpIcon /> },
//     { title: "Contact", url: "/contact", icon: <ContactSupportIcon /> },
// ];

const Header = ({ lmpRef }: any) => {
    const location = useLocation();
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleScrollToAbout = () => {
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToAbout: true } });
        } else if (lmpRef?.current) {
            const targetPosition =
                lmpRef.current.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: targetPosition - 100, behavior: "smooth" });
        }
    };

    const handleScrollToDownload = () => {
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToDownload: true } });
        } else {
            const downloadAppElement = document.getElementById("download-app");
            if (downloadAppElement) {
                const targetPosition =
                    downloadAppElement.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: targetPosition - (isMobile ? -200 : 120), behavior: "smooth" });
            }
        }
    };

    const handleScrollToHome = () => {
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToHome: true } });
        } else if (lmpRef?.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (location.state?.scrollToAbout && lmpRef?.current) {
            const scrollToAboutSection = () => {
                const targetPosition =
                    lmpRef.current.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({ top: targetPosition - 50, behavior: "smooth" });
            };

            setTimeout(scrollToAboutSection, 100);

            navigate(location.pathname, { state: {} });
        }
    }, [location, lmpRef, navigate]);


    const toggleDrawer = (open: boolean) => () => {
        setIsDrawerOpen(open);
    };

    const renderAuthButtons = () =>
        user ? (
            <>
                <Button variant="outlined" sx={{ fontWeight: '700', borderRadius: 28, px: 3, color: "#080A52", border: "2px solid #080A52", textTransform: "none" }} onClick={logout}>
                    Logout
                </Button>
                <Button variant="contained" sx={{ fontWeight: '700', borderRadius: 28, px: 3, color: "#fff", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }} onClick={() => navigate("/dashboard")}>
                    Dashboard
                </Button>
            </>
        ) : (
            <>
                <Button
                    variant="outlined"
                    sx={{
                        borderRadius: 28,
                        px: 3,
                        color: "#080A52",
                        border: "2px solid #080A52",
                        textTransform: "none",
                        fontWeight: "700",
                    }}
                    component={Link}
                    to={"/login"}
                >
                    Login
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: 28,
                        px: 3,
                        color: "#fff",
                        textTransform: "none",
                        fontWeight: "700",
                        boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)",
                    }}
                    component={Link}
                    to={"/register"}
                >
                    Register
                </Button>
            </>
        );

    return (
        <AppBar position="sticky" elevation={0} className="header-menu" sx={{ backgroundColor: "#fff", height: "100px", display: "flex", justifyContent: "center" }}>
            <Toolbar sx={{ paddingLeft: isMobile ? "10px" : "45px", paddingRight: isMobile ? "10px" : "45px" }}>
                <Grid sx={{ flexGrow: 1 }}>
                    <Link to="/">
                        <img src={isMobile ? logoMobile : logo} alt="logo" style={{ width: isMobile ? "48px" : "190px" }} />
                    </Link>
                </Grid>
                {isMobile ? (
                    <>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(!isDrawerOpen)}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "6px",
                                "& .bar": {
                                    width: "35px",
                                    height: "6px",
                                    backgroundColor: "#080A52",
                                },
                            }}
                        >
                            <div className="bar" />
                            <div className="bar" />
                            <div className="bar" />
                        </IconButton>

                        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                            <Box
                                sx={{
                                    width: "100vw",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: 2,
                                    backgroundColor: "#fff",
                                    background: "linear-gradient(to bottom, #080A52, #050529)",
                                }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <Box
                                    sx={{
                                        padding: 1,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Link to="/">
                                        <img src={logo} alt="logo" style={{ width: "150px" }} />
                                    </Link>
                                    {/* x icon */}
                                    <div
                                        className="xIcon"
                                        style={{
                                            position: "relative",
                                            width: "35px",
                                            height: "35px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            className="xbar"
                                            style={{
                                                position: "absolute",
                                                width: "35px",
                                                height: "4px",
                                                backgroundColor: "#fff",
                                                transform: "rotate(45deg)",
                                            }}
                                        ></div>
                                        <div
                                            className="xbar"
                                            style={{
                                                position: "absolute",
                                                width: "35px",
                                                height: "4px",
                                                backgroundColor: "#fff",
                                                transform: "rotate(-45deg)",
                                            }}
                                        ></div>
                                    </div>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 7 }}>
                                    <Button
                                        sx={{
                                            color: "white",
                                            textTransform: "none",
                                            fontSize: "18px",
                                            fontFamily: "Neue Haas Grotesk Display medium",
                                        }}
                                        onClick={handleScrollToHome}
                                    >
                                        Home
                                    </Button>
                                    <Button
                                        sx={{
                                            color: "white",
                                            textTransform: "none",
                                            fontSize: "18px",
                                            fontFamily: "Neue Haas Grotesk Display medium",
                                        }}
                                        onClick={handleScrollToDownload}
                                    >
                                        Download
                                    </Button>
                                    <Button
                                        sx={{
                                            color: "white",
                                            textTransform: "none",
                                            fontSize: "18px",
                                            fontFamily: "Neue Haas Grotesk Display medium",
                                        }}
                                        onClick={() => navigate("/faqs")}
                                    >
                                        FAQs
                                    </Button>
                                </Box>
                                <Box sx={{ display: "flex", gap: "20px", justifyContent: "center", marginTop: 5 }}>
                                    {user ? (
                                        <>
                                            <Box
                                                sx={{
                                                    borderRadius: 28,
                                                    px: 3,
                                                    py: 1,
                                                    color: "#fff",
                                                    border: "2px solid #fff",
                                                    textTransform: "none",
                                                }}
                                                onClick={logout}
                                            >
                                                <Typography>Logout</Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    borderRadius: 28,
                                                    px: 3,
                                                    py: 1,
                                                    color: "#fff",
                                                    textTransform: "none",
                                                    backgroundColor: "#3399FF",
                                                    display: "flex",
                                                    alignItems: "center"
                                                }}
                                                onClick={() => navigate("/dashboard")}
                                            >
                                                <Typography>Dashboard</Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: 28,
                                                    px: 3,
                                                    color: "#fff",
                                                    border: "2px solid #fff",
                                                    textTransform: "none",
                                                }}
                                                component={Link}
                                                to={"/login"}
                                            >
                                                Login
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    borderRadius: 28,
                                                    px: 3,
                                                    color: "#fff",
                                                    textTransform: "none",
                                                }}
                                                component={Link}
                                                to={"/register"}
                                            >
                                                Register
                                            </Button>
                                        </>
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "auto", marginBottom: "50px" }}>
                                    <img src={LMP} alt="lmp logo" />
                                </Box>
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 5,
                                flexDirection: "row",
                                marginRight: 5,
                                color: "#000",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                sx={{
                                    color: "black",
                                    textTransform: "none",
                                    fontSize: "18px",
                                    fontFamily: "Neue Haas Grotesk Display medium",
                                }}
                                onClick={handleScrollToDownload}
                            >
                                Download
                            </Button>
                            <Button
                                sx={{
                                    color: "black",
                                    textTransform: "none",
                                    fontSize: "18px",
                                    fontFamily: "Neue Haas Grotesk Display medium",
                                }}
                                onClick={() => navigate("/faqs")}
                            >
                                FAQs
                            </Button>
                        </Box>
                        {renderAuthButtons()}
                    </Grid>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
