import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



interface ProviderProps {
  user: any,
  token: string,
  login(data: { email: string, password: string }, navigateTo?: string): void,
  logout(): void,
  setUser(user: any): void,
}

const AuthContext = createContext<ProviderProps>({
  user: null,
  token: '',
  login: () => { },
  logout: () => { },
  setUser: () => { }
})

export const randomAlphaNumeric = (length: number) => {
  let s = '';
  Array.from({ length }).some(() => {
    s += Math.random().toString(36).slice(2);
    return s.length >= length;
  });
  return s.slice(0, length);
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<string | null>('')
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  const login = (data: { email: string, password: string }, navigateTo?: string) => {
    console.log('login data', data);
    axios.post(process.env.REACT_APP_API_URL + '/login', data)
      .then(function (response: any) {
        console.log('login response', response);
        if (response.status === 200 && response.data) {
          const result = response.data;
          console.log('login result', result);
          setUser(result.user);
          setToken(result.token);

          localStorage.setItem('user', JSON.stringify(result.user));
          localStorage.setItem('token', result.token);

          if (navigateTo) {
            navigate(navigateTo);
          }
        } else {
          let result = {
            "type": "error",
            "message": response.message ?? 'Invalid Username/Password',
          };

          return result;
        }
      })
      .catch(function (error: any) {
        console.log(error);
        let result = {
          "type": "error",
          "message": "Something went wrong",
        };

        return result;
      });
  }

  const logout = () => {
    // add api for logout
    setUser(null);
    setToken('');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/');
  }

  const updateUser = () => {
    axios.get(process.env.REACT_APP_API_URL + '/user', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(function (response: any) {
        console.log('update user response', response);
        setUser(response.data.user);
      })
      .catch(function (error: any) {
        console.log(error);
        logout();
      });
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setToken(localStorage.getItem('token') || '');
      updateUser();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, token, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}