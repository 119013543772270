import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import usePageTitle from 'hooks/usePageTitle';
import Notification from 'messages/Notification';
import ResetForm from 'pages/ResetPassword/ResetForm';
import { useState } from 'react';

const ResetPassword = () => {
  const [result, setResult] = useState({
    "type": "",
    "message": "",
  });

  usePageTitle('Change Password');

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      minHeight="50vh"
      textAlign="center"
      sx={{ my: 15, maxWidth: '550px', mx: 'auto' }}
    >
      {result.type === "success" && Notification("success", result.message)}
      {result.type === "error" && Notification("error", "Something went wrong.")}
      <Typography variant="h6" sx={{ mb: 0, fontWeight: 'bold' }}>Change Password</Typography>
      <Box>
        <Typography>
          Please create a new secure Password
        </Typography>
      </Box>
      <ResetForm updateResult={setResult} />
    </Box>
  )
};

export default ResetPassword;