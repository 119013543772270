import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Formik } from "formik";
import React from 'react';
import * as Yup from "yup";

type ForgotProps = {
  updateResult: any
};

const ForgotForm: React.FC<ForgotProps> = ({ updateResult }) => {
  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <Box>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          axios.post(process.env.REACT_APP_API_URL + '/reset', values)
            .then(function (response: any) {
              if (response.status === 200) {
                updateResult({
                  "type": "success",
                  "message": response.message,
                });
              }
            })
            .catch(function (error: any) {
              console.log(error);
              updateResult({
                "type": "error",
                "message": "Something went wrong",
              });
            });

          resetForm();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="error"
              fullWidth={true}
              size="large"
              sx={{ my: 2 }}
            >
              RESET PASSWORD
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotForm;