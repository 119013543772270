import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import usePageTitle from 'hooks/usePageTitle';
import Notification from 'messages/Notification';
import ForgotForm from 'pages/ForgotPassword/ForgotForm';
import { useState } from 'react';

const ForgotPassword = () => {
  const [result, setResult] = useState({
    type: '',
    message: '',
  });

  usePageTitle('Forgot Password');

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      minHeight="50vh"
      textAlign="center"
      sx={{ my: 15, maxWidth: '550px', mx: 'auto' }}
    >
      {result.type === 'success' && Notification("success", "Password reset request has been sent.")}
      {result.type === 'error' && Notification("error", result.message ?? "Something went wrong.")}
      <Typography variant="h6" sx={{ mb: 0, fontWeight: 'bold' }}>Reset Your Password</Typography>
      <Box>
        <Typography>
          Enter your email and we'll send you
        </Typography>
      </Box>
      <Box>
        <Typography>
          a link to reset your password
        </Typography>
      </Box>
      <ForgotForm updateResult={setResult} />
    </Box>
  )
};

export default ForgotPassword;