import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Formik } from "formik";
import React from 'react';
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
type ContactProps = {
  onSuccess: any
};

const ContactForm: React.FC<ContactProps> = ({ onSuccess }) => {
  const navigate = useNavigate();
  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile_number: Yup.number().typeError("Invalid Mobile Number").required(),
    message: Yup.string().required(),
  });

  return (
    <Box>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          mobile_number: '',
          message: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          axios.post(process.env.REACT_APP_API_URL + '/contact', values)
            .then(function (response: any) {
              if (response.status === 200) {
                onSuccess(true);
                // wait 3 seconds before redirecting to dashboard
                setTimeout(() => {
                  navigate("/thank-you");
                }, 3000);
              }
            })
            .catch(function (error: any) {
              console.log(error);
            });

          resetForm();
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              margin="normal"
              required
              fullWidth
              id="first_name"
              placeholder="First Name"
              name="first_name"
              onChange={handleChange}
              value={values.first_name}
            />
            {errors.first_name && touched.first_name ? (
              <Typography color="red" textAlign="left">{errors.first_name}</Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              placeholder="Last Name"
              name="last_name"
              onChange={handleChange}
              value={values.last_name}
            />
            {errors.last_name && touched.last_name ? (
              <Typography color="red" textAlign="left">{errors.last_name}</Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && touched.email ? (
              <Typography color="red" textAlign="left">{errors.email}</Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile_number"
              placeholder="Mobile Number"
              name="mobile_number"
              onChange={handleChange}
              value={values.mobile_number}
            />
            {errors.mobile_number && touched.mobile_number ? (
              <Typography color="red" textAlign="left">{errors.mobile_number}</Typography>
            ) : null}
            <Box>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="message"
                placeholder="Message"
                name="message"
                onChange={handleChange}
                multiline
                rows={5}
                value={values.message}
              />
            </Box>
            {errors.message && touched.message ? (
              <Typography color="red" textAlign="left">{errors.message}</Typography>
            ) : null}
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="error"
              fullWidth={true}
              size="large"
              sx={{ my: 2 }}
            >
              CONTACT US NOW
            </Button>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ContactForm;