import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function StepsBlock() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const stepsData = [
        {
            heading: "Register and subscribe",
            text: "Sign up online before downloading the app. You'll need to verify your identity, so have your licence on hand.",
        },
        {
            heading: "Download the CrashLaw24 app",
            text: "We’re only a tap away on Apple and Android devices, available via the Apple App Store and Google Play.",
        },
        {
            heading: "Set up your device",
            text: "Follow the in-app instructions to ensure you get the best out of our service, and can always reach us.",
        },
        {
            heading: "Drive with confidence",
            text: "Enjoy peace of mind knowing we're by your side if the worst should happen.",
        },
    ];

    return (
        <Box sx={{ marginTop: "88px", width: "100%", display: "flex", flexDirection: "column", paddingLeft: "30px", paddingRight: "20px" }}>
            <Box sx={{ display: isMobile ? "flex" : isTablet ? "grid" : "flex", gridTemplateColumns: "repeat(2, 1fr)", flexDirection: isTablet ? "column" : "row", justifyContent: "center", gap: "30px" }}>
                {stepsData.map((item, i) => (
                    <Box key={i} sx={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "left" : "center", width: isMobile ? "100%" : "278px", height: isMobile ? "auto" : "auto", margin: isMobile ? "0 0 20px" : isTablet ? "0 auto" : "0" }}>
                        <Typography
                            sx={{
                                backgroundColor: "#3399FF",
                                borderRadius: "100px",
                                width: "47px",
                                height: "47px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "white",
                                textAlign: "center",
                                fontSize: "30px",
                                fontWeight: "700",
                                marginBottom: "15px",
                            }}
                        >
                            <span style={{ fontSize: "20px" }}>{i + 1}</span>
                        </Typography>
                        <Typography sx={{ fontWeight: "700", fontSize: "30px", textAlign: isMobile ? "left" : "center", lineHeight: "31px", marginBottom: "11px", paddingX: "2px", color: "#080A52" }}>{item.heading}</Typography>
                        <Typography sx={{ fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", fontSize: "18px", textAlign: isMobile ? "left" : "center", paddingX: "2px", color: "#080A52" }}>{item.text}</Typography>
                    </Box>
                ))}
            </Box>
            <>
                <Button component={Link} to={"/faqs"} variant="contained" sx={{ fontWeight: '700', borderRadius: 28, px: 3, color: "#fff", textTransform: "none", margin: isMobile ? "20px 10px 30px 0px" : isTablet ? "55px auto" : "55px auto", width: "155px", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }}>
                    Read our FAQs
                </Button>
            </>
        </Box>
    );
}
