import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "contexts/AuthProvider";
import usePageTitle from "hooks/usePageTitle";
import SuccessNotification from "messages/SuccessNotification";
import { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import BillingStatus from "../../assets/icons/billing-status.svg";
import IDStatus from "../../assets/icons/id-status.svg";

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  usePageTitle("Dashboard");
  const { user } = useAuth();
  const manageBilling = () => {
    // Open billing portal in new tab
    window.open(process.env.REACT_APP_STRIPE_BILLING_URL, "_blank");
  };

  const queryParams = new URLSearchParams(window.location.search);
  const subscriptionSuccess =
    queryParams.get("subscription_success") === "true";

  const [result, setResult] = useState({
    type: "",
    message: "",
  });

  useEffect(() => {
    if (subscriptionSuccess) {
      setResult({
        type: "success",
        message: "Subscription successful. Thank you! You may now use the app.",
      });
    }
  }, [subscriptionSuccess]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user.needs_subscription || user.needs_verification) {
    return <Navigate to="/verification" />;
  }

  return (
    <Box minHeight="50vh" sx={{ maxWidth: "550px", margin: "50px auto", paddingX: isMobile ? "30px" : "0" }}>

      {result.type === "success" && SuccessNotification(result.message)}

      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        Welcome, {user.first_name} {user.last_name}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: {
            xs: "flex-start",
            sm: "center",
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box component="img" src={IDStatus} alt="Billing Status" />
          <Typography>ID Status:</Typography>
          <Typography fontWeight={700}>
            {user.needs_verification ? "Pending" : "Verified"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box component="img" src={BillingStatus} alt="Billing Status" />
          <Typography>Billing Status:</Typography>
          <Typography fontWeight={700}>
            {user.needs_subscription ? "Not-Active" : "Active"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%", mt: 5 }}>
        <Typography
          variant="h6"
          sx={{ mb: 2, display: "flex", alignItems: "center" }}
        >
          <DownloadIcon sx={{ mr: 1 }} />
          Download the App
        </Typography>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <Button
            component="a"
            href={process.env.REACT_APP_APP_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            size="large"
            sx={{
              flex: 1,
              height: "56px",
              fontSize: {
                xs: "13.5px",
                sm: "16px",
              },
            }}
            startIcon={<AppleIcon />}
          >
            App Store
          </Button>
          <Button
            component="a"
            href={process.env.REACT_APP_PLAY_STORE_URL}
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            size="large"
            sx={{
              flex: 1,
              height: "56px",
              fontSize: {
                xs: "13.5px",
                sm: "16px",
              },
            }}
            startIcon={<AndroidIcon />}
          >
            Play Store
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: 5,
          flexDirection: "column",
          gap: {
            xs: 1,
            sm: 2,
          },
          "@media (min-width:420px)": {
            flexDirection: "row",
          },
        }}
      >
        <Button
          type="button"
          variant="contained"
          size="large"
          sx={{
            borderRadius: 10,
            fontSize: {
              xs: "14px",
              sm: "16px",
              color: "white",
            },
            my: {
              xs: 1,
              sm: 2,
            },
          }}
          onClick={() => {
            manageBilling();
          }}
        >
          MANAGE BILLING
        </Button>
        <Button
          variant="contained"
          type="submit"
          size="large"
          sx={{
            borderRadius: 10,
            fontSize: {
              xs: "14px",
              sm: "16px",
              color: "#fff",
              backgroundColor: "#080A52",
            },
            my: {
              xs: 1,
              sm: 2,
            },
          }}
          component={Link}
          to={"/profile"}
        >
          Update Profile
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;
