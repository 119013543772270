import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { useAuth } from "contexts/AuthProvider";
import { Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

type RegisterProps = {
  updateResult: any;
};

const RegistrationForm: React.FC<RegisterProps> = ({ updateResult }) => {
  const [error] = useState<string>("");
  const { login } = useAuth();

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile_number: Yup.number().typeError("Invalid Mobile Number").required(),
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password_confirmation: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  return (
    <Box>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          mobile_number: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          axios
            .post(process.env.REACT_APP_API_URL + "/register", values)
            .then(function (response: any) {
              if (response.status === 200) {
                updateResult({
                  type: "success",
                  message: response.data.message,
                });
                login({
                  email: response.data.user.email,
                  password: values.password,
                  // user: response.data.user,
                  // needs_verification: response.data.needs_verification,
                  // needs_subscription: response.data.needs_subscription,
                }, '/register-success');
              } else {
                updateResult({
                  type: "error",
                  message: response.message,
                });
              }
            })
            .catch(function (error: any) {
              console.log(error);

              if (error.response.status === 500 && error.response.data.message === "This email is already used") {
                updateResult({
                  type: "error",
                  message: `Welcome back! You already have an account.`,
                  link: `/login`,
                  linkTwo: `/forgot-password`,
                  linkText: "Log in",
                  linkTextTwo: "or reset your password",
                });
              } else {
                updateResult({
                  type: "error",
                  message: "Something went wrong",
                });
              }
            });

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            {error && <p className="text-error-500">{error}</p>}
            <TextField
              margin="normal"
              required
              fullWidth
              id="first_name"
              placeholder="First Name"
              name="first_name"
              onChange={handleChange}
              value={values.first_name}
              slotProps={{
                htmlInput: { maxLength: 50 },
              }}
            />
            {errors.first_name && touched.first_name ? (
              <Typography color="red" textAlign="left">
                {errors.first_name}
              </Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              placeholder="Last Name"
              name="last_name"
              onChange={handleChange}
              value={values.last_name}
              slotProps={{
                htmlInput: { maxLength: 50 },
              }}
            />
            {errors.last_name && touched.last_name ? (
              <Typography color="red" textAlign="left">
                {errors.last_name}
              </Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              onChange={handleChange}
              value={values.email}
              slotProps={{
                htmlInput: { maxLength: 50 },
              }}
            />
            {errors.email && touched.email ? (
              <Typography color="red" textAlign="left">
                {errors.email}
              </Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile_number"
              placeholder="Mobile Number"
              name="mobile_number"
              onChange={handleChange}
              value={values.mobile_number}
              slotProps={{
                htmlInput: { maxLength: 20 },
              }}
            />
            {errors.mobile_number && touched.mobile_number ? (
              <Typography color="red" textAlign="left">
                {errors.mobile_number}
              </Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              placeholder="Password"
              name="password"
              onChange={handleChange}
              value={values.password}
              type="password"
              slotProps={{
                htmlInput: { maxLength: 20 },
              }}
            />
            {errors.password && touched.password ? (
              <Typography color="red" textAlign="left">
                {errors.password}
              </Typography>
            ) : null}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password_confirmation"
              placeholder="Confirm Password"
              name="password_confirmation"
              onChange={handleChange}
              value={values.password_confirmation}
              type="password"
              slotProps={{
                htmlInput: { maxLength: 20 },
              }}
            />
            {errors.password_confirmation && touched.password_confirmation ? (
              <Typography color="red" textAlign="left">
                {errors.password_confirmation}
              </Typography>
            ) : null}
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              fullWidth={true}
              size="large"
              sx={{ my: 2, color: "white" }}
            >
              {isSubmitting ? "LOADING.." : "CREATE ACCOUNT"}
            </Button>
          </form>
        )}
      </Formik>
      <Link to="/login">
        <Typography>Back to Login</Typography>
      </Link>
    </Box>
  );
};

export default RegistrationForm;
