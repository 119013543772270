import usePageTitle from "hooks/usePageTitle";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

declare global {
    interface Window {
        adalyserTracker?: (event: string, code: string, data: object, bool: boolean) => void;
    }
}

const RegisterSuccess = () => {
    const navigate = useNavigate();

    usePageTitle("Register Success");

    useEffect(() => {
        console.log("RegisterSuccess");
        window.adalyserTracker?.("trackEvent", "lce3", {}, true);
    }, []);


    return (
        <Box sx={{ marginBottom: '300px', marginTop: '80px', width: "100%", marginX: "auto", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontFamily: "Univia Pro", fontSize: "25px", textAlign: 'center' }}>
                Thank you for completing your registration
            </Typography>
            <Button variant="contained" sx={{
                width: 'fit-content',
                fontWeight: '700', borderRadius: 28,
                margin: '20px auto',
                px: 3, color: "#fff", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            }} onClick={() => navigate("/dashboard")}>
                Go To Dashboard
            </Button>
        </Box >
    );
};

export default RegisterSuccess;
