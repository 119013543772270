import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "contexts/AuthProvider";
import usePageTitle from "hooks/usePageTitle";
import Notification from "messages/Notification";
import RegistrationForm from "pages/Register/RegistrationForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { user } = useAuth();
  const navigate = useNavigate();
  const [result, setResult] = useState({
    type: "",
    message: "",
    link: "",
    linkText: "",
    linkTwo: "",
    linkTextTwo: "",
  });

  usePageTitle("Create Your Account");

  if (user) {
    navigate("/dashboard");
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ my: 15, maxWidth: "550px", mx: "auto", px: isMobile ? "30px" : "0" }}
    >
      {result.type === "success" && Notification("success", result.message)}
      {result.type === "error" &&
        Notification("error", result.message ?? "Something went wrong.", result.link ?? "", result.linkText ?? "", result.linkTwo ?? "", result.linkTextTwo ?? "")}
      <Typography variant="h6" sx={{ mb: 0, fontWeight: "bold", color: "#080A52" }}>
        Create Your Account
      </Typography>
      <Box>
        <Typography sx={{ fontFamily: "Neue Haas Grotesk Display medium", color: "#080A52" }}>
          Enter all of your details below to create an account and you will
          receive an email.
        </Typography>
      </Box>
      <RegistrationForm updateResult={setResult} />
    </Box>
  );
};

export default Register;
