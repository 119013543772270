import Box from "@mui/material/Box";
import AboutApp from "components/AboutApp";
import CtaBlock from "components/CtaBlock";
import FaqBlock from "components/FaqBlock";
import Hero from "components/Hero";
import KeyDifferences from "components/KeyDifferences";
import LmpBlock from "components/LmpBlock";
import StepsBlock from "components/StepsBlock";
import { useRef } from "react";

export default function Home( { lmpRef }: any) {
  const keyDiff = useRef(null);

  return (
    <Box>
      <Hero />
      <AboutApp keyDiff={keyDiff}/>
      <StepsBlock />
      <div ref={keyDiff}>
        <KeyDifferences />
      </div>
      <FaqBlock />
      <CtaBlock />
      <div ref={lmpRef}>
        <LmpBlock />
      </div>
    </Box>
  );
};
