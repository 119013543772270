import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import send from 'assets/images/send.svg';
import usePageTitle from 'hooks/usePageTitle';
import SuccessNotification from 'messages/SuccessNotification';
import ContactForm from 'pages/Contact/ContactForm';
import { useState } from 'react';

const Contact = () => {
  const [success, setSuccess] = useState(false);

  usePageTitle('Contact Us');

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      sx={{ my: 15, maxWidth: '550px', mx: 'auto' }}
    >
      {success && SuccessNotification("Your message was sent successfully.")}
      <Stack direction="row"
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img src={send} alt="send contact form message" />
        <Typography justifyContent="center" variant="h3" sx={{ mb: 0, fontWeight: 'bold' }}> Contact Us</Typography>
      </Stack>
      <ContactForm onSuccess={setSuccess} />
    </Box>
  )
};

export default Contact;