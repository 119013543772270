import usePageTitle from "hooks/usePageTitle";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
    const navigate = useNavigate();

    usePageTitle("Thank You");

    usePageTitle("Subscription Success");

    return (
        <Box sx={{ height: "calc(100vh - 750px)", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
            <Typography sx={{ fontFamily: "Univia Pro", fontSize: "25px", textAlign: 'center' }}>
                Thank you for your submission
            </Typography>
            <Button variant="contained" sx={{
                width: 'fit-content',
                fontWeight: '700', borderRadius: 28,
                margin: '20px auto',
                px: 3, color: "#fff", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)",
            }} onClick={() => navigate("/dashboard")}>
                Go To Dashboard
            </Button>
        </Box >
    );
};

export default ThankYou;
