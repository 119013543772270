import CircleIcon from '@mui/icons-material/Circle';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid2,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import usePageTitle from 'hooks/usePageTitle';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: 24,
    width: '90%',
    maxWidth: '600px',
};

const HowItWorks = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);

    usePageTitle('How It Works');

    const handleOpenModal = (id: string) => {
        const content = modalContents[id as keyof typeof modalContents];
        console.log(content);
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalContent(null);
    };

    return (
        <Box sx={{ marginTop: 4 }}>
            {/* How Does It Work Section */}
            <Box sx={{ mt: 8 }}>
                <Typography
                    variant="h2"
                    fontWeight={900}
                    align="left"
                    sx={{
                        mb: 4,
                        fontSize: isMobile ? 28 : 36,
                    }}
                >
                    HOW DOES IT WORK?
                </Typography>
                <Grid2 container spacing={3}>
                    {[
                        { id: 'getYourDeviceReady', title: 'Get Your Device Ready', content: 'CONTENT FOR GET YOUR DEVICE READY' },
                        { id: 'whenToCallUs', title: 'When To Call Us', content: 'CONTENT FOR WHEN TO CALL US' },
                        { id: 'afterAnAccident', title: 'After An Accident', content: 'CONTENT FOR AFTER AN ACCIDENT' },
                        { id: 'frequentlyAskedQuestions', title: 'Frequently Asked Questions', link: '/faqs' },
                    ].map((item: any, index: number) => (
                        <Grid2 size={{ xs: 12, md: 6, sm: 6 }} key={item.id}>
                            <Card
                                sx={{
                                    backgroundColor: 'white',
                                    color: '#13182C',
                                    cursor: 'pointer',
                                    height: '100%', // Add this line
                                    display: 'flex', // Add this line
                                    flexDirection: 'column' // Add this line
                                }}
                                onClick={() =>
                                    item.link ? navigate(item.link) : handleOpenModal(item.id)
                                }
                            >
                                <CardContent sx={{
                                    padding: '15px !important',
                                    flex: 1, // Add this line,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <Typography
                                            variant="h3"
                                            fontWeight="bold"
                                            sx={{
                                                color: '#13182C',
                                                fontSize: isMobile ? 24 : 64,
                                                fontWeight: 900,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {index + 1}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: '#13182C',
                                                fontSize: 24,
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))}
                </Grid2>
            </Box>

            {/* Modal */}
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={modalStyles}>
                    {modalContent}
                    <Button
                        variant="contained"
                        onClick={handleCloseModal}
                        sx={{
                            backgroundColor: '#E63946',
                            color: 'white',
                            mt: 2,
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

const modalContents = {
    getYourDeviceReady: (
        <Box sx={{ padding: 2, overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#13182C' }}>
                Get Your Device Ready
            </Typography>
            <Typography sx={{ color: '#13182C' }}>
                To ensure that your experience is as smooth as possible when making a crisis call, please
                follow these simple steps to prepare your device. This will help us assist you quickly and
                effectively when it matters most.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                1. Check Your Permissions
            </Typography>
            <Typography sx={{ color: '#13182C' }} >
                <strong>Android:</strong>
                <ul>
                    <li>Calls: Ensure the app can make and manage phone calls. Go to setting → Apps → CrashLaw24 → Permissions → Phone → Select Allow</li>
                    <li>Location: Share your location automatically with our legal team when calling. Go to Settings → Apps → CrashLaw24 → Permissions → Location → Allow</li>
                </ul>
                <strong>iOS:</strong>
                <ul>
                    <li>Calls: Allow CrashLaw24 to make calls. Go to Settings → Apps → CrashLaw24 → Mobile Data on</li>
                    <li>Location: Let the app automatically detect your location during a crisis call. Go to Settings → Apps → CrashLaw24 → Location → Always</li>
                </ul>
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                2. Add a Backup Phone Number to Your Contacts
            </Typography>
            <Typography sx={{ color: '#13182C' }}>
                In case of network issues or if the app is unable to connect, make sure you have the
                CrashLaw24 crisis number saved in your contacts for quick dialing.
            </Typography>
        </Box>
    ),
    whenToCallUs: (
        <Box sx={{ padding: 2, overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#13182C' }}>
                When To Call Us
            </Typography>
            <Typography sx={{ color: '#13182C' }} >
                The CrashLaw24 crisis response service is here to support you in the immediate aftermath
                of serious road traffic incidents. Knowing when to call will help us provide the right
                legal support when it matters most.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                Call the Crisis Line If:
            </Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="The police or emergency services are present or have been called." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="You have been involved in a serious collision, resulting in significant damage, injury, or potential legal consequences." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="You are being questioned by the police at the scene or anticipate being interviewed soon." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="There is a risk of criminal charges, and you need immediate legal advice to avoid self-incrimination." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="You are unsure of your legal obligations or rights at the scene of the accident." />
                </ListItem>
            </List>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                Do Not Call If:
            </Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="The accident involves minor damage and both parties are exchanging insurance details amicably." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="There are no injuries and no police or emergency services have been contacted." />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon sx={{ fontSize: 8, color: '#13182C' }} />
                    </ListItemIcon>
                    <ListItemText primary="The issue is related to vehicle repairs, insurance claims, or other non-legal matters that can be handled without immediate legal intervention." />
                </ListItem>
            </List>
        </Box >
    ),
    afterAnAccident: (
        <Box sx={{ padding: 2, overflowY: 'auto', maxHeight: '80vh' }}>
            <Typography variant="h6" component="h2" sx={{ mb: 2, color: '#13182C' }}>
                After An Accident
            </Typography>
            <Typography sx={{ color: '#13182C' }} >
                Follow these steps to protect yourself after an accident:
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                1. Ensure Everyone’s Safety
            </Typography>
            <Typography sx={{ color: '#13182C' }} >
                Your first priority is to ensure your safety and the safety of others at the scene. If
                possible, move yourself to a safe location away from further danger. Check whether anyone
                involved in the accident is injured and offer assistance if you are able to.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 700, color: '#13182C' }}>
                2. Stay Calm
            </Typography>
            <Typography sx={{ color: '#13182C' }}   >
                You have the right to remain silent until a lawyer is with you.
                Only give the police your name, contact details and vehicle registration.
            </Typography>
        </Box >
    ),
};


export default HowItWorks;
