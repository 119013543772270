import { Box, Typography, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo-white.svg";
import LmpLogo from "../assets/icons/LMP-footer.svg";
import FooterBG from "../assets/images/footer-background.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import { useAuth } from "contexts/AuthProvider";


const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    // const { user } = useAuth();
    const location = useLocation();

    const isAuthPage = ["/register-success", "/thank-you", "/login", "/register", "/verification", "/profile", "/dashboard", '/subscription-success'].includes(location.pathname);

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", position: "relative", marginTop: "150px" }}>
            {!isAuthPage ? (<Box
                sx={{
                    width: isMobile ? "90%" : "99%",
                    height: isMobile ? "210px" : "250px",
                    borderRadius: "15px",
                    position: "absolute",
                    top: isMobile ? "-105px" : "-125px",
                    left: "50%",
                    transform: "translate(-50%)",
                    backgroundImage: `url(${FooterBG})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingX: isMobile ? "0" : isTablet ? "50px" : isLargeScreen ? "130px" : "100px",
                }}
            >
                <Typography sx={{ fontSize: isMobile ? "30px" : "40px", lineHeight: "32px", color: isMobile ? "white" : "#080A52", textAlign: isMobile ? "center" : "left" }}>Ready to get started?</Typography>
                <Button sx={{ fontWeight: '700', backgroundColor: "#080A52", color: "white", borderRadius: "100px", textTransform: "none", width: "172px", margin: isMobile ? "20px auto 0 auto" : isLargeScreen ? "30px 0 0 0" : "30px 0 0 0px", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }} component={Link} to={"/register"}>
                    Register today
                </Button>
            </Box>) : null}
            <Box sx={{ background: "linear-gradient(to bottom, #080A52, #050529)", height: isMobile ? "617px" : isAuthPage ? "400px" : "550px", display: "flex", paddingBottom: "110px" }}>
                <Box sx={{ maxWidth: "1210px", width: "100%", margin: isMobile ? "150px 0 0 0 " : "auto auto 0 auto", height: isMobile ? "auto" : "190px", display: "flex", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row", alignItems: "center" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
                        <img src={Logo} alt="" style={{ width: "277px" }} />
                        {!isMobile && (<Box sx={{ marginTop: "130px", display: "flex", alignItems: "center" }}>
                            <Link to={"/terms-and-conditions"} style={{ fontFamily: "Neue Haas Grotesk Display light", fontSize: "13px", borderBottom: "1px solid white", color: "white" }}>
                                Terms and conditions
                            </Link>
                            <div style={{ width: "5px", height: "5px", backgroundColor: "#3399ff", borderRadius: "50px", margin: "0 15px 0 38px" }}></div>
                            <Link to="/privacy-policy" style={{ fontFamily: "Neue Haas Grotesk Display light", fontSize: "13px", borderBottom: "1px solid white", color: "white" }}>
                                Privacy policy
                            </Link>
                        </Box>)}
                        {!isMobile && <Link to="https://pragmaticdigital.co.uk" style={{ position: "absolute", bottom: "-40px", left: "0", color: "white", fontFamily: "Neue Haas Grotesk Display light", fontSize: "12px", marginTop: "20px" }}>Built by Pragmatic Digital</Link>}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <img src={LmpLogo} alt="" className="" width={isMobile ? "100px" : "auto"} style={{ margin: isMobile ? "auto" : "0", paddingTop: isMobile ? "30px" : "0" }} />
                        <Button variant="contained" component={Link} to={"https://www.lmplegal.co.uk/"} sx={{ fontWeight: '700', width: "140px", borderRadius: "100px", textTransform: "none", color: "white", margin: isMobile ? "50px auto 0 auto" : "30px 0 0 0", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }}>
                            Visit LMP Legal
                        </Button>
                        {isMobile && (<Box sx={{ marginTop: "45px", display: "flex", alignItems: "center" }}>
                            <Link to={"/terms-and-conditions"} style={{ fontFamily: "Neue Haas Grotesk Display light", fontSize: "13px", borderBottom: "1px solid white", color: "white" }}>
                                Terms and conditions
                            </Link>
                            <div style={{ width: "5px", height: "5px", backgroundColor: "#3399ff", borderRadius: "50px", margin: "0 16px 0 16px" }}></div>
                            <Link to="/privacy-policy" style={{ fontFamily: "Neue Haas Grotesk Display light", fontSize: "11px", borderBottom: "1px solid white", color: "white" }}>
                                Privacy policy
                            </Link>
                        </Box>)}
                        {isMobile && <Link to="https://pragmaticdigital.co.uk" style={{ color: "white", fontFamily: "Neue Haas Grotesk Display light", fontSize: "12px", margin: "20px auto 0 auto" }}>Built by Pragmatic Digital</Link>}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
