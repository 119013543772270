import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ErrorNotification = (message: string, link?: string, linkText?: string, linkTwo?: string, linkTextTwo?: string) => {
  return (
    <Stack
      className="message-error"
      direction="row"
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}>
      <CancelIcon />
      <Typography justifyContent="center">
        {message}  {link && (
          <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>
            {linkText}
          </a>
        )}
        <br />
        {linkTwo && (
          <a href={linkTwo} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'underline' }}>
            &nbsp; {linkTextTwo}
          </a>
        )}
      </Typography>

    </Stack>
  )
};

export default ErrorNotification;