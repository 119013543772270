import Image from "../assets/images/diff-img.jpeg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import True from "../assets/icons/true.svg";
import False from "../assets/icons/False.svg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function KeyDifferences() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const compareData = [
        {
            heading: "Access to legal help at the roadside immediately after a collision.",
            legal: false,
            crashlaw: true,
        },
        {
            heading: "24/7/365 app that connects you with a specialist lawyer within minutes.",
            legal: false,
            crashlaw: true,
        },
        {
            heading: "Specialist transport lawyer at the first police interview anywhere in England and Wales.",
            legal: false,
            crashlaw: true,
        },
        {
            heading: "Not dependent upon the accident being non-fault.",
            legal: false,
            crashlaw: true,
        },
        {
            heading: "Covers legal costs for criminal court proceedings once prosecuted.",
            legal: true,
            crashlaw: false,
        },
    ];

    return (
        <Box sx={{ background: "linear-gradient(to bottom, #E02642, #7A1524)", marginX: isTablet ? "0" : "15px", borderRadius: isTablet ? "0" : "10px", }}>
            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "1210px", width: "100%", margin: "0 auto", }}>
                <Box sx={{ paddingX: isTablet ? "35px" : "20px", display: "flex", gap: "30px", paddingTop: isTablet ? "35px" : "100px", flexDirection: isTablet ? "column" : "row" }}>
                    <Box sx={{ width: isTablet ? "auto" : "50%" }}>
                        <img src={Image} alt="" style={{ width: "100%", objectFit: isTablet ? "cover" : "contain", height: isTablet ? "330px" : "100%", borderRadius: "15px", }} />
                    </Box>
                    <Box sx={{ color: "white", display: "flex", flexDirection: "column", justifyContent: "center", width: isTablet ? "auto" : "50%" }}>
                        <Typography sx={{ fontFamily: "Univia Pro", fontWeight: "700", fontSize: isTablet ? "32px" : "45px", marginBottom: "25px", lineHeight: isTablet ? "33px" : "45px" }}>But my insurance covers legal expenses, doesn’t it?</Typography>
                        <Typography sx={{ fontFamily: "Neue Haas Grotesk Display medium", fontWeight: "450", fontSize: "18px", paddingRight: "10px", lineHeight: "30px" }}>
                            The legal expenses cover that comes with your motor insurance isn’t enough on its own. CrashLaw24 has been designed to fill the gaps in legal expenses to provide uninterrupted protection.
                            {isTablet && <Box sx={{ height: "10px" }} />} Our expert guidance starts at the side of the road immediately after a collision when you need help the most – long before legal expenses cover begins.
                            <br />
                            <Link to={"/faqs?q=18"} style={{ color: "white", textDecoration: "underline", fontWeight: "700", }}>Read our FAQ to learn more</Link>
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ paddingTop: isTablet ? "30px" : "60px", paddingX: isTablet ? "35px" : "0", paddingBottom: "59px" }}>
                    <Box sx={{ borderBottom: "1px solid white", display: "flex", textAlign: "center", }}>
                        <Box sx={{ width: "50%", margin: "25px" }}></Box>
                        {!isTablet && <Typography sx={{ color: "white", width: "25%", fontSize: "22px", fontWeight: "700", marginBottom: "25px", }}>Legal expense cover</Typography>}
                        {!isTablet && <Typography sx={{ color: "white", width: "25%", fontSize: "22px", fontWeight: "700", marginBottom: "25px", }}>CrashLaw24</Typography>}
                    </Box>
                    {compareData.map((item, i) => (
                        <Box sx={{ borderBottom: "1px solid white", display: "flex", flexDirection: isTablet ? "column" : "row", alignItems: "center", paddingY: "25px" }}>
                            <Typography sx={{ width: isTablet ? "100%" : "50%", color: "white", fontSize: isTablet ? "20px" : "22px", fontFamily: "Univia Pro", lineHeight: "21px", margin: "25px", }}>{item.heading}</Typography>
                            <Box
                                sx={{
                                    color: "white",
                                    width: isTablet ? "100%" : "25%",
                                    fontSize: isTablet ? "16px" : "20px",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: isTablet ? "space-between" : "center",
                                    alignItems: "center",
                                    marginBottom: isTablet ? "25px" : "0",

                                }}
                            >
                                {isTablet && <Typography sx={{ fontFamily: "Univia Pro" }}>Legal expense cover</Typography>}
                                <img src={item.legal ? True : False} alt="" className="" style={{ width: isTablet ? "30px" : "auto", height: isTablet ? "30px" : "auto" }} />
                            </Box>
                            <Box
                                sx={{
                                    color: "white",
                                    width: isTablet ? "100%" : "25%",
                                    fontSize: isTablet ? "16px" : "20px",
                                    fontWeight: "700",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: isTablet ? "space-between" : "center",
                                    alignItems: "center",

                                }}
                            >
                                {isTablet && <Typography sx={{ fontFamily: "Univia Pro" }}>CrashLaw24</Typography>}
                                <img src={item.crashlaw ? True : False} alt="" style={{ width: isTablet ? "30px" : "auto", height: isTablet ? "30px" : "auto" }} />
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ display: "flex", justifyContent: isMobile ? "left" : "center", paddingBottom: "100px", paddingX: isTablet ? "35px" : "0" }}>
                    <Button sx={{ fontWeight: '700', color: "white", backgroundColor: "#080A52", padding: "10px 20px", borderRadius: "50px", textTransform: "none", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", }} component={Link} to={"/register"}>
                        Register for CrashLaw24
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
