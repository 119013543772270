import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';

const Notification = (type: string, message: string, link?: string, linkText?: string, linkTwo?: string, linkTextTwo?: string) => {
  return (
    <>
      {type === "success" && SuccessNotification(message)}
      {type === "error" && ErrorNotification(message, link, linkText, linkTwo, linkTextTwo)}
    </>
  )
};

export default Notification;