import { Box, Typography, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import LogoLmp from "../assets/icons/LMP Logo Vector 14192D 1 (1).svg";

export default function LmpBlock() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Box id="about" sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", maxWidth: "1210px", width: "100%", margin: "0 auto", paddingY: isTablet ? "70px" : "92px", gap: isMobile ? "30px" : "90px", paddingX: isMobile ? "30px" : "50px", alignItems: isMobile ? "left" : isTablet ? "center" : "left" }}>
                <img src={LogoLmp} alt="" style={{ width: isTablet ? "152px" : "278px", height: isTablet ? "152px" : "278px", margin: isMobile ? "auto" : "0" }} />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
                    <Typography sx={{ fontWeight: '700', fontSize: "40px", lineHeight: "42px", color: "#080A52", textAlign: isMobile ? "left" : isTablet ? "center" : "left" }}>An app by LMP Legal</Typography>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Neue Haas Grotesk Display light", fontWeight: "450", }}>
                        LMP Legal stands at the forefront of private criminal defence, delivering unparalleled, round-the-clock support to private clients, motorists, and fleet operators throughout the UK. With offices in both London and Leeds, and a
                        modern, dynamic network of specialist lawyers spanning England and Wales, we provide immediate, high-calibre criminal defence and regulatory expertise in transport law whenever and wherever our clients need us.
                    </Typography>
                    <Button variant="contained" target="blank" sx={{ fontWeight: '700', fontSize: "16px", color: "#fff", textTransform: "none", borderRadius: "100px", width: "180px", boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.2)", marginX: isMobile ? "0" : isTablet ? "auto" : "0" }} component={Link} to={"https://www.lmplegal.co.uk/"}>
                        Visit LMP website
                    </Button>
                </Box>
            </Box>
        </>
    );
}
