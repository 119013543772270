import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import usePageTitle from 'hooks/usePageTitle';
import { useEffect, useState } from 'react';
import ArrowUp from "../../assets/icons/arrow_circle_up.svg";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import { useParams, useLocation } from 'react-router-dom';

type FAQItem = {
    id: string;
    question: string;
    answer: string;
};

export default function FaqPage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    // Get the query parameter
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const q = queryParams.get('q');

    const faqData: FAQItem[] = [
        { id: '1', question: 'What is CrashLaw24?', answer: 'CrashLaw24 is a legal service providing individual drivers with 24/7 access to immediate roadside legal advice and representation from specialist solicitors in the event of a serious roadside collision. It is designed to fill the gap that exists between the moment a collision occurs, and when your legal expenses cover is able to provide you with representation. CrashLaw24 provides the individual with representation at the initial police interview as well as access to a lawyer within seconds of a collision occurring. ' },
        { id: '2', question: 'Who can sign up for CrashLaw24?', answer: 'This service is available to individual personal drivers who want access to legal advice and support during a serious roadside collision or any other potential police matter where they would like advice and representation from our specialist team of criminal lawyers.' },
        { id: '3', question: 'Should I sign up if I drive for work?', answer: "Yes, you can and should sign up for the CrashLaw24 service even if you drive for work, as it is unlikely that your employer has this service in place for you. In the event that they are members of the corporate version of CrashLaw24 (also known as LMP FLEET), this cover would only extend to you during your working hours.\n There are additional benefits for companies signing up to CrashLaw24 on behalf of their drivers, and we would recommend they get in touch to explore our corporate service if they are not already an existing client. " },
        { id: '4', question: "My employer has this cover for when I'm driving for work. Am I covered when I drive outside of work?", answer: 'If your employer provides CrashLaw24 coverage for work-related driving, this only applies during work hours or when you are driving for work purposes. To ensure you are covered outside of work or during personal driving, you would need to sign up for the personal CrashLaw24 subscription independently.' },
        { id: '5', question: 'What do I get as part of my CrashLaw24 subscription?', answer: 'Subscribers receive access to a 24/7, 365-day a year crisis line which you can use to receive immediate legal advice at the scene of a collision, and in-person representation at one initial police interview. The subscription includes all return travel for that lawyer, across England and Wales, and a follow up email with advice as to next steps you should take.' },
        { id: '6', question: 'What does the crisis line cover?', answer: 'The crisis line connects you instantly with a specialist solicitor who will provide expert legal advice and support at the scene of a serious road traffic accident. This service is designed to help you avoid self-incrimination which often leads to people being charged and/or convicted of a serious offence which can ultimately lead to custodial sentences. It provides advice and support when you need it the most.' },
        { id: '7', question: 'What happens if I need further legal help after my first police interview?', answer: 'If you require further legal assistance following your initial police interview, you will need to instruct a criminal solicitor to represent you. As a CrashLaw24 subscriber, you will benefit from a discounted rate on any additional legal services should you choose to instruct LMP Legal.' },
        { id: '8', question: 'Am I covered regardless of whether I was at fault in the accident?', answer: 'Yes, the service covers legal advice regardless of fault, as its primary purpose is to ensure you have access to a solicitor when you need legal assistance at the scene and for the first police interview. Even when you are not at fault, it is important to protect your position as soon as possible.\n\nIt is important to note that, particularly in road traffic collisions, you will not know whether you were ‘at fault’ or not for some time, and certainly not immediately at the scene.\n\nThat’s why it’s important not to make any admissions of fault or responsibility at the scene, regardless of what you may believe. Admitting fault early could have serious legal and financial consequences. The purpose of the CrashLaw24 service is to ensure you receive legal advice before making any statements. Your solicitor will guide you on how to protect your rights and avoid unnecessary self-incrimination.' },
        { id: '9', question: 'Does my subscription cover representation in court?', answer: 'The initial subscription only covers advice at the scene of the collision and representation at the first police interview. Any court representation would require private engagement, which is available at a discounted rate from LMP Legal for our subscribers.' },
        { id: '10', question: 'How do I access the crisis line in the app?', answer: 'After subscribing, you will receive a digital confirmation of the agreement, details of your coverage, and instructions on how to use the crisis line, all accessible through the app.' },
        { id: '11', question: 'Can I sign up for CrashLaw24 for my family members or friends?', answer: "At present, each driver must sign up individually. You cannot sign up on behalf of someone else unless they are using the app personally. We are currently working on a referral program to reward users who recommend signing up to their family members and friends." },
        { id: '12', question: 'What do I do if I forget my login details?', answer: 'Use the "Forgot Password" option on the login page to reset your credentials. Instructions will be sent to your registered email address.' },
        { id: '13', question: 'What happens if the app crashes or I can’t access the crisis line?', answer: "In the rare event that the app crashes or you cannot access the crisis line through the app, you can still call the CrashLaw24 Crisis Line by dialling directly using a phone number provided in the app. You will only be able to dial this Crisis Line number from the phone number registered on your account, so please ensure you keep your profile up to date." },
        { id: '14', question: 'What should I do immediately after a collision?', answer: "Safety first: ensure that everyone involved is safe and, if necessary, call the emergency services if someone else has not already done this. Once safe, use the app to call our crisis line for legal advice on handling the situation." },
        { id: '15', question: 'Should I speak to the police before calling the CrashLaw24 crisis line?', answer: "It is recommended that you contact the crisis line first. A solicitor will advise you on what to say to the police to avoid self-incrimination. One of our solicitors will then ask you to pass the phone on to the police so that they can speak with them directly to advise them of our involvement and to ask when and where you are to be interviewed." },
        { id: "16", question: "What am I legally obliged to tell the police at the scene of a road traffic accident?", answer: "There are a few things that you must do to comply with the Road Traffic Act 1988. You must:\n\n• Provide the police with your personal details, to include your insurance details.\n\n\• Tell the police which vehicle you were driving\n\n• Comply with any roadside testing.\n\nAfter that, you should advise the police that you have your legal representative on the phone, or that you need to call them if you haven’t already, and that you will not answer further questions until you have done so. You can also provide the CrashLaw24 emergency contact number to the police to call if they have already confiscated your mobile phone." },
        { id: '17', question: 'What should I avoid doing at the scene?', answer: "Avoid admitting fault, signing any documents, or making statements or discussing the accident with anyone, including any witnesses, until you have received legal advice from your solicitor." },
        { id: '18', question: 'I have ticked ‘legal expense cover’ under my insurance policy so I already have this legal cover don’t I?', answer: "No. Legal expenses cover is provided under your insurance policy and whilst it may well cover you, this will be further down the line. In order to receive legal representation through your legal expenses cover, you’d need to make contact with your insurer, and not all insurance companies will answer at any time of the day, or as immediately as you’d need them to. Even if they do, they will not be able to have a criminal lawyer on hand within minutes to provide you with that critical immediate advice and dispatch to the police station to provide you with representation.\n\nIt is also important to note that legal expenses cover for motoring offences is almost always discretionary, and there is no guarantee you will be provided with cover for representation. CrashLaw24 will give you advice and representation regardless of the circumstances, and is available to immediately fill the gap between the collision occurring and the insurance cover potentially helping at a later date, likely after the roadside account and initial police station interview. Remember that the initial comments you make at the scene and in the initial interview with the police can be the most critical parts of the process, and cases are often won and lost from comments made in the immediate aftermath of an accident." },
        { id: '19', question: 'How much does the CrashLaw24 service cost?', answer: "Subscription fees are paid at £4.99 monthly, and this grants you access to the crisis line, and includes free representation at any first police interview, plus a post-interview advice email outlining the next steps in your case." },
        { id: '20', question: 'How do I make a payment?', answer: "Payments are automatically debited monthly from the payment method you provided when signing up. Our payment processor is Stripe." },
        { id: '21', question: 'What happens if my payment fails?', answer: "You will be notified by Stripe if your payment fails. Service may be paused until the payment is rectified." },
        { id: '22', question: 'Can I cancel my subscription at any time?', answer: "Yes, you can cancel your subscription at any time. Coverage will cease at the end of the current month you have been billed for." }
    ];

    const [expanded, setExpanded] = useState<string | false>(q || faqData[0].id);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    usePageTitle('FAQs');


    useEffect(() => {
        // scroll to the faq is query param of q is present
        const scrollToElement = () => {
            if (q) {
                const element = document.getElementById(`accordion-${q}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            } else {
                window.scrollTo(0, 0);
            }
        };
        const timeoutId = setTimeout(scrollToElement, 100);
        return () => clearTimeout(timeoutId);
    }, [q]);

    return (
        <Box sx={{ py: 4, px: '0!important', maxWidth: "1210px", margin: "0 auto", pt: "50px", paddingX: isMobile ? "30px" : "0" }}>
            <Typography variant="h3" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 900, fontSize: 36, color: "#080A52" }}>
                FAQs
            </Typography>

            <Box sx={{ mb: 8, }}>
                {faqData.map((item) => (
                    <Accordion
                        key={item.id}
                        id={`accordion-${item.id}`}
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                        sx={{
                            mb: "12px",
                            width: "100%",
                            backgroundColor: expanded === item.id ? "#fff" : "#3399FF",
                            borderRadius: "15px !important",
                            paddingY: "5px",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={expanded === item.id ? <img src={ArrowUp} alt="Arrow up" /> : <img src={ArrowDown} alt="Arrow down" />}
                            sx={{
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                    color: "error.main",
                                    transform: "none!important"
                                },
                            }}
                        >
                            <Typography fontWeight="bold" sx={{ width: "100%", flexShrink: 0, color: expanded === item.id ? "#080A52" : "#fff", fontSize: "20px", lineHeight: "21px" }}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography color="#080A52" whiteSpace="pre-line" sx={{ fontFamily: "Neue Haas Grotesk Display medium", fontSize: "18px", lineHeight: "22.5px" }}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    );
}
